// src/App.js
import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Articles from './components/Articles';
import CapHitPredictor from './components/CapHitPredictor';
import FeedbackTHS from './components/FeedbackTHS';
import Support from './components/Support';
import Article from './components/Article';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ModelNotes from './components/ModelNotes'; // Import the ModelNotes component
import Footer from './components/Footer'; // Import the Footer component
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PP';
import Clusters from './components/Clusters';
import ClusterModelNotes from './components/ClusterModelNotes'; // Import the ModelNotes component
import TeamRankingsGrid from './components/TeamRankingGrid'; // Import the grid component
import TeamProfile from './components/TeamProfile';
// import SkaterRankings from './components/SkaterRankings'; // Import the grid component
// import ViewAllSkaters from "./components/ViewAllSkaters";
// import PlayerProfile from "./components/PlayerProfile";





function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/articles/:articleId" element={<Article />} />
            <Route path="/cap-hit-predictor" element={<CapHitPredictor />} />
			<Route path="/clustering" element={<Clusters />} />
            <Route path="/feedback" element={<FeedbackTHS />} />
			<Route path="/support" element={<Support />} />
			<Route path="/model-notes" element={<ModelNotes />} />
			<Route path="/terms-of-service" element={<TermsOfService />} />
			<Route path="/privacy-policy" element={<PrivacyPolicy />} />
			<Route path="/cluster-model-notes" element={<ClusterModelNotes />} />
			<Route path="/team-ratings" element={<TeamRankingsGrid />} />
			<Route path="/team/:teamId" element={<TeamProfile />} />

			{/* <Route path="/skater-ratings" element={< SkaterRankings />} />
			<Route path="/skaters/:position" element={<ViewAllSkaters />} />
			<Route path="/player/:playerId" element={<PlayerProfile />} /> */}
          </Routes>
		  <div className="hidden-text">
		  	<Footer />
		  </div>

        </div>
      </div>
    </Router>
  );
}

export default App;
