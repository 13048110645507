import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  return (
    <nav className="navbar">
      <div className='navbar-brand'>
        <img src="/images/logo.png" alt="Logo" className="logo" />
        <h1 className='navbar-title'>The Hockey Science</h1>
        <a href="https://x.com/TheHockeySci" target="_blank" rel="noopener noreferrer" className="footer-icon">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="https://buymeacoffee.com/thehockeyscience" target="_blank" rel="noopener noreferrer" className="footer-icon">
          <i className="fas fa-coffee"></i>
        </a>
      </div>
      <div className="links">
        <Link to="/">Home</Link>
        <Link to="/articles">Articles</Link>
        <div className="dropdown">
          <button className="dropbtn">
            Models <i className="fas fa-caret-down"></i>
          </button>
          <div className="dropdown-content">
            <Link to="/cap-hit-predictor">Cap Hit Predictor</Link>
            <Link to="/clustering">Clustering Teams</Link>
			<Link to="/team-ratings">Team Power Ratings</Link>
			{/* <Link to="/skater-ratings">Skater Ratings</Link> */}
            {/* Add more links for other Shiny apps here */}
          </div>
        </div>
        <Link to="/feedback">Feedback</Link>
        <Link to="/support">Support</Link>
      </div>
    </nav>
  );
}

export default Navbar;
