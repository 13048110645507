import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Papa from 'papaparse';
import { Line, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import './TeamProfile.css'; // Import the CSS file
import teamColors from '../assets/teamColors.json';


const TeamProfile = () => {
  const { teamId } = useParams();
  const [teamData, setTeamData] = useState([]);
  const [leagueAverages, setLeagueAverages] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch and parse CSV data
    const fetchData = async () => {
      try {
        const response = await fetch('/PowerRatings_Weekly.csv');
        const csvText = await response.text();

        Papa.parse(csvText, {
          header: true,
          complete: (result) => {
            const data = result.data;

            // Filter for the selected team
            const filteredData = data.filter(item => item.Team === teamId);
            setTeamData(filteredData);

            // Calculate league averages
            const metrics = ['Power Rating', 'W', 'RW', 'GF', 'GA', 'xGoalsFor', 'xGoalsAgainst'];
            const leagueTotals = {};
            const leagueCounts = {};

            metrics.forEach(metric => {
              leagueTotals[metric] = 0;
              leagueCounts[metric] = 0;
            });

            data.forEach(item => {
              metrics.forEach(metric => {
                if (item[metric]) {
                  leagueTotals[metric] += parseFloat(item[metric]);
                  leagueCounts[metric] += 1;
                }
              });
            });

            const averages = {};
            metrics.forEach(metric => {
              averages[metric] = leagueTotals[metric] / leagueCounts[metric];
            });

            setLeagueAverages(averages);
            setLoading(false);
          },
        });
      } catch (error) {
        console.error('Error loading CSV data:', error);
        setLoading(false);
      }
    };

	    // Fetch and calculate league averages from PowerRatings_Latest.csv
		const fetchLeagueAverages = async () => {
			try {
			  const response = await fetch('/PowerRatings_Latest.csv');
			  const csvText2 = await response.text();
	  
			  Papa.parse(csvText2, {
				header: true,
				complete: (results) => {
				  const data = results.data;
	  
				  // Calculate league averages from the latest data
				  const metrics = ['Power Rating', 'W', 'RW', 'GF', 'GA', 'xGoalsFor', 'xGoalsAgainst'];
				  const leagueTotals = {};
				  const leagueCounts = {};
	  
				  metrics.forEach(metric => {
					leagueTotals[metric] = 0;
					leagueCounts[metric] = 0;
				  });
	  
				  data.forEach(item => {
					metrics.forEach(metric => {
					  if (item[metric]) {
						leagueTotals[metric] += parseFloat(item[metric]);
						leagueCounts[metric] += 1;
					  }
					});
				  });
	  
				  const averages = {};
				  metrics.forEach(metric => {
					averages[metric] = leagueTotals[metric] / leagueCounts[metric];
				  });
	  
				  setLeagueAverages(averages);
				},
			  });
			} catch (error) {
			  console.error('Error loading league averages:', error);
			}
		  };
	  
		  fetchData();
		  fetchLeagueAverages(); // Fetch the latest data for league averages
		}, [teamId]);
	  
		if (loading) {
		  return <div>Loading team data...</div>;
		}


  // Prepare data for the chart
  const labels = teamData.map(item => item.week);
  const powerRatings = teamData.map(item => parseFloat(item['Power Rating']));
  const ranks = teamData.map(item => parseInt(item['Rank']));
  const teamColor = teamColors[teamId] || '#FFFFFF'; // Default to white if not found
  
  // Calculate season high, season low, recent weekly change, and current rating
  const seasonHigh = Math.max(...powerRatings);
  const seasonLow = Math.min(...powerRatings);
  const recentChange = powerRatings[powerRatings.length - 1] - powerRatings[powerRatings.length - 2];
  const recentChangeDisplay = isNaN(recentChange) ? "None" : recentChange;
  
  const currentRating = powerRatings[powerRatings.length - 1];

  // Calculate ranks based on power ratings
  const seasonHighRank = Math.max(...ranks); // Ranks start from 1
  const seasonLowRank = Math.min(...ranks); // Ranks start from 1
  
  const recentChangeColor = recentChange >= 0 ? 'green' : 'red';

  // Determine the color based on the last two power ratings
  const lastIndex = powerRatings.length - 1;
  const isIncreasing = powerRatings[lastIndex] >= powerRatings[lastIndex - 1];
  const lineColor = isIncreasing ? 'green' : 'red';
  const shadeColor = isIncreasing ? 'rgba(0, 255, 0, 0.2)' : 'rgba(255, 0, 0, 0.2)'; // Change shade color based on trend

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: `Power Rating for ${teamId}`,
        data: powerRatings,
        borderColor: lineColor, // Set the entire line color
        borderWidth: 2,
        backgroundColor: shadeColor, // Change shaded color based on trend
        pointBackgroundColor: '#fff',
        fill: true, // Fill the area below the line
        tension: 0.3,  // Smooth curves
      },
    ],
  };

  // Define chart options
  const chartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            return `Week: ${tooltipItems[0].label}`; // Display "Week:" in front of the number
          },
          label: function (context) {
            return `Power Rating: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Week',
          color: 'white', // Set x-axis title color to white
        },
        ticks: {
          color: 'white', // Set x-axis ticks color to white
        },
        grid: {
          display: false, // Remove vertical gridlines
        },
      },
      y: {
        title: {
          display: true,
          text: 'Power Rating',
          color: 'white', // Set y-axis title color to white
        },
        min: 0,   // Set minimum value of y-axis to 0
        max: 100, // Set maximum value of y-axis to 100
        ticks: {
          color: 'white', // Set y-axis ticks color to white
          stepSize: 50,
        },
      },
    },
  };

  // Prepare data for the bar chart
  const teamMetrics = [
    parseFloat(teamData[teamData.length - 1]['Power Rating']),
    parseInt(teamData[teamData.length - 1]['W']),
    parseInt(teamData[teamData.length - 1]['RW']),
    parseInt(teamData[teamData.length - 1]['GF']),
    parseInt(teamData[teamData.length - 1]['GA']),
    parseFloat(teamData[teamData.length - 1]['xGoalsFor']),
    parseFloat(teamData[teamData.length - 1]['xGoalsAgainst'])
  ];

  const leagueMetrics = [
    leagueAverages['Power Rating'],
    leagueAverages['W'],
    leagueAverages['RW'],
    leagueAverages['GF'],
    leagueAverages['GA'],
    leagueAverages['xGoalsFor'],
    leagueAverages['xGoalsAgainst']
  ];

  const barData = {
    labels: ['Power Rating', 'W', 'Regulation W', 'GF', 'GA', 'xGoalsFor', 'xGoalsAgainst'],
    datasets: [
      {
        label: `${teamId}`,
        data: teamMetrics,
        backgroundColor: teamColor, // Blue
        borderColor: teamColor,
        borderWidth: 1,
      },
      {
        label: 'League Average',
        data: leagueMetrics,
        backgroundColor: '#fff', // Red
        borderColor: '#fff',
        borderWidth: 1,
      },
    ],
  };

  const barOptions = {
	maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'white', // Legend text color
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Metrics',
          color: 'white',
        },
        ticks: {
          color: 'white',
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Values',
          color: 'white',
        },
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
    },
  };

  return (
	<div className="profile-container">
	  <div className="profile-content">
		<div className="left-column">
		  <h2 className="chart-title" style={{color: teamColor}}>{teamId} Power Rating Chart</h2>
		  <div className="chart-container">
			<Line data={chartData} options={chartOptions} />
		  </div>
		  <div className="callout-cards">
			<div className="card">
			  <h2>Current Rating</h2>
			  <p>{currentRating}</p>
			</div>
			<div className="card">
			  <h2>Season High</h2>
			  <p>{seasonHigh}</p>
			</div>
			<div className="card">
			  <h2>Season High Rank</h2>
			  <p>{seasonHighRank}</p>
			</div>
			<div className="card" style={{ color: recentChangeColor }}>
			  <h2>Rating Change</h2>
			  <p style={{ color: recentChange >= 0 ? 'green' : 'red' }}>{recentChangeDisplay.toFixed(2)}</p>
			</div>
			<div className="card">
			  <h2>Season Low</h2>
			  <p>{seasonLow}</p>
			</div>
			<div className="card">
			  <h2>Season Low Rank</h2>
			  <p>{seasonLowRank}</p>
			</div>
		  </div>
		</div>
		<div className="right-column">
		  <h2 className="bar-chart-title" style={{color: teamColor}}>{teamId} Metrics vs League Average</h2>
		  <div className="bar-chart-container">
			<Bar data={barData} options={barOptions} />
		  </div>
		</div>
	  </div>
	</div>
  );  
};

export default TeamProfile;
